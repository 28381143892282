// Note: Requires flexslider 2 buddy.
jQuery(document).ready(function($) {
	$('.testimonial-wrapper:not(.one-slide) .flexslider').flexslider({
		animation: "fade",
		prevText: "",
		nextText: "",
		controlNav: false,
		directionNav: true,
	});

}); /* end of as page load scripts */